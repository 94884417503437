import { useCallback, useMemo, useState } from 'react';
import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonType } from '../../shared/form-control/Button';
import { InputStyle } from '../../shared/form-control/Input';
import { SearchInput } from '../../shared/form-control/SearchInput';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import CogIcon from '../../shared/icon/CogIcon';
import { ImageSize, ProfileImageStack } from '../../ownership/ProfileImageStack';
import { ClientModuleTemplateDefault } from '../../../models/ClientModuleDefaults';
import LanguageUtils from '../../../utils/LanguageUtils';
import Tooltip from '../../shared/Tooltip';
import { ClientFormUserDto } from '../../../models/ClientFormUser';

const ManageClassesStep = () => {
  const { classes, cancel, clientUsers, setSelectedClass, nextStep } = useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'organisation', 'common']);
  const [searchQuery, setSearchQuery] = useState('');

  const getClientFormUsers = useCallback(
    (defaultUsers: ClientFormUserDto[]) => {
      if (defaultUsers && clientUsers.length) {
        const users = (defaultUsers || []).flatMap((u) =>
          u.users.map((x) => {
            const clientUser = clientUsers.find((user) => user.id && user.id === x.userId)!;
            return { ...clientUser, ...x, role: u.role };
          }),
        );
        return users;
      }
      return [];
    },
    [clientUsers],
  );

  const selectClass = useCallback(
    (value: ClientModuleTemplateDefault) => {
      setSelectedClass(value);
      nextStep && nextStep('details');
    },
    [nextStep, setSelectedClass],
  );

  const filteredClasses = useMemo(() => {
    return classes.filter((x) => {
      const query = searchQuery.toLowerCase();
      const templateName = LanguageUtils.getTranslation('name', x.templateModule.translations || {}).toLowerCase();
      return templateName.includes(query);
    });
  }, [classes, searchQuery]);

  return (
    <StandardModal
      title={t('manage-wizard.classes.title')}
      cancelButtonTitle={t('manage-wizard.buttons.cancel')}
      onCancelClick={() => cancel && cancel()}
      confirmButtonTitle={t('manage-wizard.buttons.done')}
      onConfirmClick={() => cancel && cancel()}
      tertiaryButtonIcon={null}
    >
      <div className="h-[60vh] flex-grow overflow-auto">
        <div className="z-modal sticky top-0 flex justify-between gap-2 bg-white pb-2 pr-2">
          <div className="flex gap-2">
            <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => nextStep && nextStep('createClass')}>
              {t('manage-wizard.buttons.new')}
            </Button>
            <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => nextStep && nextStep('importClass')}>
              {t('manage-wizard.buttons.import-class')}
            </Button>
          </div>
          <SearchInput
            data-cy="doc-class-search"
            placeholder={t('common:list.filter.search')}
            style={InputStyle.MINIMAL}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <div className="mt-5">
          <div className="border-gray-6 flex gap-x-4 border-b pb-4 font-medium">
            <div className="w-1/2 flex-grow truncate">{t('manage-wizard.classes.list.name')}</div>
            <div className="w-2/8">{t('manage-wizard.classes.list.prefix')}</div>
            <div className="w-2/8">{t('manage-wizard.classes.list.members')}</div>
            <div className="w-10"></div>
          </div>
          {filteredClasses.map((classTemplateDefaults) => {
            const nameTooltipText = LanguageUtils.getTranslation('name', classTemplateDefaults.templateModule.translations || {});
            const prefixTooltipText = LanguageUtils.getTranslation('prefix', classTemplateDefaults.templateModule.translations || {});

            return (
              <div key={classTemplateDefaults.templateModule.id} className="border-gray-6 flex items-center gap-x-4 border-b py-2">
                <Tooltip text={nameTooltipText} truncatedTextMode>
                  {(tooltip) => (
                    <div {...tooltip} className="w-1/2 flex-grow truncate">
                      {nameTooltipText}
                    </div>
                  )}
                </Tooltip>
                <Tooltip text={prefixTooltipText} truncatedTextMode>
                  {(tooltip) => (
                    <div {...tooltip} className="w-2/8 truncate">
                      {prefixTooltipText}
                    </div>
                  )}
                </Tooltip>
                <div className="w-2/8">
                  {(classTemplateDefaults.defaults?.configuration.users || []).length > 0 && (
                    <ProfileImageStack users={getClientFormUsers(classTemplateDefaults.defaults?.configuration.users || [])} size={ImageSize.S} />
                  )}
                  {(classTemplateDefaults.defaults?.configuration.users || []).length === 0 && <span>-</span>}
                </div>
                <div className="w-10">
                  <CogIcon className="h-5 w-5" onClick={() => selectClass(classTemplateDefaults)} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </StandardModal>
  );
};

export default ManageClassesStep;
